import React, { useState } from "react";
import { Container, Button } from "@mantine/core";
import "@mantine/core/styles.css";
import { useNavigate } from "react-router-dom";

const styles = {
  pageContainer: {
    minHeight: "100vh",
    backgroundColor: "#f8f9fa",
    backgroundSize: "cover, cover",
    backgroundRepeat: "no-repeat, no-repeat",
  },
  header: {
    height: "70px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#ffffff",
    padding: "0 24px",
    borderBottom: "1px solid #e1e4e8",
    borderRadius: "0 0 20px 20px",
    margin: "0px 20px 0 20px",
    boxShadow: "0 1px 1px rgba(0, 0, 0, 0.1)",
  },
  headerGroup: {
    display: "flex",
    flexWrap: "nowrap",
    marginLeft: "auto",
  },
};

const TermsAndConditions = () => {
  const navigate = useNavigate();

  return (
    <div style={styles.pageContainer}>
      <header style={styles.header}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <h3 style={{ color: "#5075B5", margin: 0 }}>animanic</h3>
        </div>
      </header>
      <Container>
        <br></br>
        <Button
          style={{
            backgroundColor: "#f8f9fa",
            color: "black",
            padding: "10px",
            borderRadius: "15px",
            border: "1px solid #000000",
          }}
          onClick={() => navigate("/")}
          variant="filled"
        >
          ← Home
        </Button>
        <br></br>
        <h2>Terms and Conditions</h2>
        <h3>1. Introduction</h3>
        <p>
          Welcome to Animanic (“Company,” “we,” “our,” “us”). These Terms and
          Conditions (“Terms”) govern your use of our website, animanic.site
          (the “Site”), and any services or products provided by Animanic
          (collectively, the “Services”). By accessing or using the Site and
          Services, you agree to be bound by these Terms. If you do not agree to
          these Terms, please do not use the Site or Services.
        </p>

        <h3>2. Use of the Site and Services</h3>
        <p>
          You agree to use the Site and Services only for lawful purposes and in
          accordance with these Terms. You are responsible for your use of the
          Site and Services and for ensuring that your use complies with all
          applicable laws and regulations.
        </p>

        <h3>3. User Accounts</h3>
        <p>
          To access certain features of the Site or Services, you may be
          required to create an account. You agree to provide accurate, current,
          and complete information during the registration process and to update
          such information to keep it accurate, current, and complete. You are
          responsible for safeguarding your account login credentials and for
          any activities or actions under your account. You agree to notify us
          immediately of any unauthorized use of your account.
        </p>

        <h3>4. Intellectual Property</h3>
        <p>
          All content, features, and functionality on the Site, including but
          not limited to text, graphics, logos, images, and software, are the
          exclusive property of Animanic or its licensors and are protected by
          copyright, trademark, and other intellectual property laws. You may
          not reproduce, distribute, modify, or create derivative works of any
          content on the Site without our express written permission.
        </p>

        <h3>5. Orders and Payment</h3>
        <p>
          When you place an order through the Site, you agree to pay the price
          listed for the product or service, including any applicable taxes and
          fees. All payments must be made through the methods provided on the
          Site. We reserve the right to cancel or refuse any order for any
          reason, including but not limited to product availability, errors in
          the description or price of the product, or issues identified by our
          fraud detection systems.
        </p>

        <h3>6. Returns and Refunds</h3>
        <p>
          Our return and refund policy is available on the Site. Please review
          this policy carefully before making a purchase. By placing an order,
          you agree to the terms of our return and refund policy.
        </p>

        <h3>7. Limitation of Liability</h3>
        <p>
          To the maximum extent permitted by law, Animanic and its affiliates,
          officers, employees, agents, and licensors shall not be liable for any
          indirect, incidental, special, consequential, or punitive damages,
          including but not limited to loss of profits, data, use, goodwill, or
          other intangible losses, resulting from (a) your access to or use of
          or inability to access or use the Site or Services; (b) any conduct or
          content of any third party on the Site; (c) any content obtained from
          the Site; and (d) unauthorized access, use, or alteration of your
          transmissions or content, whether based on warranty, contract, tort
          (including negligence), or any other legal theory, whether or not we
          have been informed of the possibility of such damage.
        </p>

        <h3>8. Indemnification</h3>
        <p>
          You agree to defend, indemnify, and hold harmless Animanic and its
          affiliates, officers, employees, agents, and licensors from and
          against any claims, liabilities, damages, losses, and expenses,
          including without limitation reasonable legal and accounting fees,
          arising out of or in any way connected with your access to or use of
          the Site or Services, or your violation of these Terms.
        </p>

        <h3>9. Governing Law</h3>
        <p>
          These Terms and any disputes arising out of or related to these Terms
          or your use of the Site or Services shall be governed by and construed
          in accordance with the laws of the United States, without regard to
          its conflict of law principles.
        </p>

        <h3>10. Changes to These Terms</h3>
        <p>
          We reserve the right to modify these Terms at any time. Any changes
          will be effective immediately upon posting on the Site. Your continued
          use of the Site and Services following the posting of changes
          constitutes your acceptance of such changes.
        </p>

        <h3>11. Termination</h3>
        <p>
          We may terminate or suspend your access to the Site or Services at any
          time, without prior notice or liability, for any reason whatsoever,
          including without limitation if you breach these Terms. Upon
          termination, your right to use the Site and Services will immediately
          cease.
        </p>

        <h3>12. Contact Information</h3>
        <p>
          If you have any questions about these Terms, please contact us at:
        </p>
        <p>
          <strong>Email:</strong>{" "}
          <a style={{ color: "#5075B5" }} href="mailto:team@animanic.site">
            team@animanic.site
          </a>
        </p>
        <br></br>
        <br></br>
        <br></br>
      </Container>

      <style jsx="true">{`
        @keyframes scroll {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(-50%);
          }
        }
      `}</style>
    </div>
  );
};

export default TermsAndConditions;
