import React, { useState } from "react";
import { Container, Button } from "@mantine/core";
import "@mantine/core/styles.css";
import { useNavigate } from "react-router-dom";

const styles = {
  pageContainer: {
    minHeight: "100vh",
    backgroundColor: "#f8f9fa",
    backgroundSize: "cover, cover",
    backgroundRepeat: "no-repeat, no-repeat",
  },
  header: {
    height: "70px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#ffffff",
    padding: "0 24px",
    borderBottom: "1px solid #e1e4e8",
    borderRadius: "0 0 20px 20px",
    margin: "0px 20px 0 20px",
    boxShadow: "0 1px 1px rgba(0, 0, 0, 0.1)",
  },
  headerGroup: {
    display: "flex",
    flexWrap: "nowrap",
    marginLeft: "auto",
  },
};

const PrivacyPolicy = () => {
  const navigate = useNavigate();

  return (
    <div style={styles.pageContainer}>
      <header style={styles.header}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <h3 style={{ color: "#5075B5", margin: 0 }}>animanic</h3>
        </div>
      </header>
      <Container>
        <br></br>
        <Button
          style={{
            backgroundColor: "#f8f9fa",
            color: "black",
            padding: "10px",
            borderRadius: "15px",
            border: "1px solid #000000",
          }}
          onClick={() => navigate("/")}
          variant="filled"
        >
          ← Home
        </Button>
        <br></br>
        <h2>Privacy Policy</h2>
        <h3>1. Introduction</h3>
        <p>
          Welcome to Animanic (“Company,” “we,” “our,” “us”). We are committed
          to safeguarding your privacy. This Privacy Policy outlines how we
          collect, use, disclose, and protect your personal information when you
          visit and interact with our website, animanic.site (the "Site").
        </p>
        <p>
          By accessing or using the Site, you agree to the collection and use of
          your information in accordance with this Privacy Policy. If you do not
          agree with the terms, please do not use the Site.
        </p>

        <h3>2. Information We Collect</h3>
        <p>
          We collect the following personal information through our integration
          with Google when you interact with the Site:
        </p>
        <ul>
          <li>
            <strong>Name</strong>
          </li>
          <li>
            <strong>Email Address</strong>
          </li>
        </ul>

        <h3>3. How We Use Your Information</h3>
        <p>
          The personal information we collect is used for managing user-specific
          content and services.
        </p>

        <h3>4. Disclosure of Your Information</h3>
        <p>
          We do not sell, trade, or otherwise transfer your personal information
          to third parties. We may disclose your information if required to do
          so by law or in response to a legal request.
        </p>

        <h3>5. Data Security</h3>
        <p>
          We implement appropriate technical and organizational measures to
          ensure the security of your personal information. However, no method
          of transmission over the internet or electronic storage is completely
          secure, and we cannot guarantee absolute security.
        </p>

        <h3>6. Cookies</h3>
        <p>
          We do not use cookies or similar tracking technologies on the Site.
        </p>

        <h3>7. Your Rights</h3>
        <p>You have the right to:</p>
        <ul>
          <li>Access the personal information we hold about you.</li>
          <li>Request correction or deletion of your personal data.</li>
          <li>Object to the processing of your personal data.</li>
        </ul>
        <p>
          To exercise these rights, please contact us at{" "}
          <a style={{ color: "#5075B5" }} href="mailto:team@animanic.site">
            team@animanic.site
          </a>
          .
        </p>

        <h3>8. International Data Transfers</h3>
        <p>
          Your personal information may be stored and processed in the United
          States or any other country in which we or our service providers
          operate. By using the Site, you consent to the transfer of information
          to countries outside of your country of residence, which may have
          different data protection rules.
        </p>

        <h2>9. Changes to This Privacy Policy</h2>
        <p>
          We reserve the right to update or modify this Privacy Policy at any
          time, and any changes will be effective upon posting on the Site. Your
          continued use of the Site following the posting of changes constitutes
          your acceptance of such changes.
        </p>

        <h2>10. Contact Us</h2>
        <p>
          If you have any questions, concerns, or requests regarding this
          Privacy Policy, please contact us at:
        </p>
        <p>
          <strong>Email:</strong>{" "}
          <a style={{ color: "#5075B5" }} href="mailto:team@animanic.site">
            team@animanic.site
          </a>
        </p>

        <h2>Governing Law</h2>
        <p>
          This Privacy Policy and your use of the Site are governed by and
          construed in accordance with the laws of the United States.
        </p>
        <br></br>
        <br></br>
        <br></br>
      </Container>

      <style jsx="true">{`
        @keyframes scroll {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(-50%);
          }
        }
      `}</style>
    </div>
  );
};

export default PrivacyPolicy;
