import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyB7auxBWGH9_KHZj0GGKyiwj3QTpjv3Sl4",
  authDomain: "animanic-dfc20.firebaseapp.com",
  projectId: "animanic-dfc20",
  storageBucket: "animanic-dfc20.appspot.com",
  messagingSenderId: "670737196111",
  appId: "1:670737196111:web:df71a6c7cf25084ee0763a",
  measurementId: "G-M1CBBN3539",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export const initFirebase = () => {
  return app;
};

const signInWithGoogle = () => signInWithPopup(auth, provider);
const logOut = () => signOut(auth);

export { auth, signInWithGoogle, logOut };
